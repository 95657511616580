<template>
  <div class="release-card ar-card d-flex flex-column" @click="toReleasePage">
    <div v-if="release.status " class="d-flex flex-row" style="position: absolute; align-items: center; flex-direction: row-reverse; top: 1em; right: 1em;">
      <el-dropdown
        v-if="(release.status === 'Черновик' || release.status === 'Проверяется модератором') && !isModerator"
        :popper-options="{
          placement: 'bottom',
        }"
      >
        <div class="release-card__header--menu-btn">
          <el-icon style="transform: rotate(270deg);">
            <MoreFilled color="#3290FF" />
          </el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <template v-if="release.status === 'Черновик' ">
              <el-dropdown-item @click="$router.push(`/edit_draft_release/${release.id }`)">
                <el-icon size="1.5em">
                  <Edit />
                </el-icon>
                <div class="ml2">
                  Редактировать
                </div>
              </el-dropdown-item>
              <el-dropdown-item @click="$emit('delete-release',release.id)">
                <el-icon size="1.5em">
                  <Delete />
                </el-icon>
                <div class="ml2">
                  Удалить
                </div>
              </el-dropdown-item>
            </template>
            <template v-else>
              <el-dropdown-item @click="$emit('decline-release',release.id)">
                <el-icon size="2em">
                  <Pen />
                </el-icon>
                <div class="ml2">
                  Отозвать
                </div>
              </el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-popover
        :disabled="!release.comment"
        placement="top-start"
        title="Причина отказа"
        :width="200"
        trigger="hover"
        :content="release.comment"
      >
        <template #reference>
          <el-tag size="small" round :type="statusChipColor[ release.status ]" style="font-size: 0.7em; height:2em" class="mr2">
            {{ release.status }}
          </el-tag>
        </template>
      </el-popover>
    </div>
    <div class="d-flex flex-row ph4 mv4 release-card__header">
      <div>
        <el-image :src="release.imageThumbPath|| release.imagePath"
                  style="border-radius: 1em; overflow: hidden;"
        >
          <template #error>
            <div class="image-slot">
              <el-icon size="1.4em">
                <Catalog />
              </el-icon>
            </div>
          </template>
        </el-image>
      </div>

      <div class="d-flex flex-column pl4">
        <div class="release-card__header--info ">
          <div class="release-card__header--name mt2">
            {{ release.name || 'Нет названия' }}
          </div>
          <div class="release-card__header--artist">
            {{ release.headArtists.join(', ') || 'Нет исполнителя' }}
          </div>
          <div />
        </div>
        <el-row class="mt4">
          <el-col :span="12">
            <div class="release-card__data--fields mb4">
              <div>
                Жанр
              </div>
              <div>
                {{ getGenreFullName(release.genre) }}
              </div>
            </div>
            <div class="release-card__data--fields ">
              <div>
                UPC
              </div>
              <div>
                {{ release.upc || LONG_DASH }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="release-card__data--fields mb4">
              <div>
                Дата релиза
              </div>
              <div v-if="release.releaseDate">
                {{ $moment(release.releaseDate ).format('YYYY.MM.DD') }}
              </div>
              <div v-else>
                {{ LONG_DASH }}
              </div>
            </div>
            <div class="release-card__data--fields">
              <div>
                Лейбл
              </div>
              <div :title="release.copyright">
                {{ release.copyright || LONG_DASH }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Catalog,
  // Delete, Pen
} from '@/assets/icons/index.js';
import { mapGetters } from 'vuex';

export default {
  name: 'ReleaseCard',
  components: {
    Catalog,
    //  Delete,
    //  Pen,
  },
  props: {
    release: Object,
  },
  computed: {
    ...mapGetters(['isModerator']),
  },
  methods: {
    toReleasePage() {
      if (this.release.status === 'Опубликовано') {
        this.$router.push(`/release/${this.release.id}`);
      }
    },
    getGenreFullName(genre) {
      if (!genre) {
        return '—';
      }
      return `${genre.parent ? `${genre.parent.name} / ` : ''}${genre.name}`;
    },
  },
  data: () => ({
    statusChipColor: {
      Черновик: 'info',
      Опубликовано: 'success',
      'Проверяется модератором': 'warning',
      'На удалении': 'danger',
    },
  }),
};
</script>

<style lang="scss">

@media screen and (max-width: 1200px) {
  .release-card__wrapper {
    flex-basis:100%!important;
    max-width: 100%!important;
  }
}

 .release-card {
  position: relative;

  margin-bottom:1em;
  transition: .3s;

  &:hover{
    transform: scale(1.01)
  }

  &__wrapper{
    flex-basis:50%;
    max-width:50%;
  }

  &__header {
    display: flex;
    flex-direction: row;

    .el-image{
      border-radius: 1em!important;
      width: 14em !important;
      height:14em !important;

      // .image-slot{
      //   background-color: #151515;
      //   width:100%;
      //   height:100%;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      // }
    }

    &--menu-btn{
      padding: 0.25rem;
      cursor: pointer;
      transition: 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      width:2.2em;
      height:2.2em;
      border-radius: 50%;
      font-weight:500;

      .el-icon{
        color: $primaryBlue;
        font-size:1.5rem!important;
      }

      &:hover{
        background-color: rgba(255,255,255,.2);
      }
    }

    &--info{
      // width:calc(100% - 170px);
      // padding: 0.5em;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &--name{
      font-size: 1.35em !important;
      max-width:15em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      font-weight: 500;
    }

    &--artist{
      color: $subGrey1;
      font-weight: 400;
      font-size: 1.35em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
  }

  &__data {

    &--fields{
      font-weight: normal;
      font-size: 1em;
      display: flex;
      flex-direction: column;

      & :first-child{
        margin-bottom: 0.5em;
        color: $primaryBlue;
      }
      & :last-child{
        overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      }
    }
  }
 }
</style>
