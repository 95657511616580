<template>
  <div>
    <el-tabs v-model="activeTabName" class="release-list-tab">
      <el-tab-pane label="Релизы" name="releases" />
      <el-tab-pane label="Черновики" name="drafts" />
      <el-tab-pane label="Модерация" name="moderation" />
      <el-tab-pane label="На удалении" name="onDelete" />
      <el-tab-pane label="На изменении" name="onEdit" />
    </el-tabs>
    <div id="list" style="min-height:71.1111111111111112em ;">
      <div v-if="releaseList.length === 0 && !loaders.releaseList" class="mt7" style="display: flex; align-items: center; flex-direction: column;">
        <el-empty description="Нет данных" />
        <el-button type="primary" size="large" @click="$router.push('create_release')">
          Создать релиз
        </el-button>
      </div>
      <el-row :gutter="20">
        <el-col v-for="release in releaseList" :key="release.id" class="release-card__wrapper">
          <ReleaseCard :release="release" @delete-release="deleteRelease" @decline-release="declineRelsese" />
        </el-col>
      </el-row>
      <div style="display: flex; justify-content: center;">
        <el-pagination
          v-if="totalPages > 1"
          background
          :page-size="pageSize"
          :page-count="totalPages"
          layout="prev, pager, next"
          :total="totalCount"
          @current-change="pageChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElMessage,
  ElLoading,
} from 'element-plus';
import ReleaseCard from '@/components/cards/ReleaseCard.vue';

export default {
  name: 'ReleaseList',
  components: {
    ReleaseCard,
  },
  data: () => ({
    activeTabName: 'drafts',
    releaseList: [],
    loaders: {
      releaseList: false,
    },
    releasesId: {
      drafts: 0,
      moderation: 3,
      releases: 9,
      onEdit: 7,
      onDelete: 6,
    },
    pageNumber: 1,
    totalPages: 0,
    totalCount: 0,
    pageSize: 10,
  }),
  created() {
    this.getReleaseList();
  },
  watch: {
    activeTabName() {
      this.releaseList = [];
      this.getReleaseList();
    },
  },
  methods: {
    pageChange(page) {
      this.pageNumber = page;
      this.getReleaseList();
    },
    async declineRelsese(id) {
      const req = {
        status: 0,
      };
      await this.$axios.post(`release-catalog/change-release-status-by-id?releaseId=${id}`, req)
        .then(() => {
          ElMessage({ grouping: true, message: 'Заявка на модерацию возвращена', type: 'success' });
          this.getReleaseList();
        }).catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        });
    },
    async deleteRelease(id) {
      await this.$axios.delete(`release-catalog/delete-release-by-id/${id}`)
        .then(() => {
          ElMessage({ grouping: true, message: 'Релиз успешно удалён', type: 'success' });
          this.getReleaseList();
        }).catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        });
    },
    async getReleaseList() {
      const loading = ElLoading.service({
        target: '#list',
        lock: true,
        text: 'Загрузка',
        background: 'rgba(0, 0, 0, 0)',
      });
      this.loaders.releaseList = true;
      try {
        const res = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        };
        let response;
        if (this.activeTabName === 'moderation') {
          const { data } = await this.$axios.post('/release-catalog/get-releases-moderated', res);
          response = data;
        } else {
          const { data } = await this.$axios.post(`/release-catalog/get-releases-by-request-status/${this.releasesId[this.activeTabName]}`, res);
          response = data;
        }
        const {
          pageNumber, totalPages, totalCount, items,
        } = response;
        this.loaders.releaseList = false;
        this.releaseList = items;
        this.pageNumber = pageNumber;
        this.totalPages = totalPages;
        this.totalCount = totalCount;
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        loading.close();
      }
    },
  },
  computed: {

  },

};
</script>

<style lang="scss">
.release-list-tab{
  .el-tabs__header{
      align-items: center;
      justify-content: start;
    }

    .el-tabs__nav-wrap {
      flex:none!important;
    }

}
</style>
